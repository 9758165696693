<template>
  <div>
    <v-breadcrumbs
      divider="/"
      large
      :class="mobilebreakdown() ? 'p-2' : ''"
      v-if="mobilebreakdown()"
    >
      <v-breadcrumbs-item to="/home" link
        ><v-icon>mdi-home</v-icon>
      </v-breadcrumbs-item>

      <v-breadcrumbs-divider v-if="mobilebreakdown()">
        <v-icon>mdi-chevron-double-right</v-icon>
      </v-breadcrumbs-divider>

      <v-breadcrumbs-item v-if="mobilebreakdown()">
        {{ title }}
      </v-breadcrumbs-item>
    </v-breadcrumbs>
  </div>
</template>

<script>
export default {
  name: "TitleBar",
  data: () => {
    return {
      iseKYC: false,
    };
  },
  props: {
    title: {
      type: String,
      default: "Home",
    },
    isKYCCompleted: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iseKYCCompleted: {
      get: function () {
        return this.iseKYC;
      },
      set: function (newVal) {
        this.iseKYC = newVal;
      },
    },
  },
  created() {
    const complete_registration = sessionStorage.getItem("complete_registration");
    console.log("complete_registration: ", complete_registration);
    if (complete_registration) {
      this.iseKYC = false;
    } else {
      this.iseKYC = this.isKYCCompleted;
    }
  },
  mounted() {
    this.iseKYC = this.isKYCCompleted;
  },
  methods: {
    mobilebreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return false;
        case "sm":
          return false;
        case "md":
          return true;
        case "lg":
          return true;
        case "xl":
          return true;
      }
    },
    webbreakdown() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return true;
        case "sm":
          return true;
        case "md":
          return false;
        case "lg":
          return false;
        case "xl":
          return false;
      }
    },
    navUpdate() {
      this.$router.push({ name: "HomeView" });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.warning-alert {
  color: red;
  font-weight: 900;
  padding: 5px;
  width: 600px;
}
.title-bar {
  background: $gainsboro;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.back-arrow {
  width: 40px;
  height: 40px;
}
.title {
  text-align: left;
  width: 100%;
  color: $dimgrey;
  font-weight: bold;
  font-size: 24px;
  margin: 0 15px;
}

.v-breadcrumbs--large li {
  font-size: 18px;
}
.v-breadcrumbs--large li .v-icon {
  font-size: 24px;
}

.v-breadcrumbs--large li a:hover {
  text-decoration: none !important;
}
.faicon {
  font-size: 18px;
}
.btn {
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.active-col {
  color: $primary;
}

.menu-style {
  font-size: 10px;
}
.v-breadcrumbs {
  padding: 0px;
}
</style>
