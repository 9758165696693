<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="6">
        <label>{{ $t("firstName") }}<span style="color: #f00">*</span></label>
        <v-text-field
          dense
          :placeholder="$t('first_name_ind')"
          v-model="addProfilePayload.first_name"
          required
          :error-messages="fnameErrors"
          @input="$v.addProfilePayload.first_name.$touch()"
          @blur="$v.addProfilePayload.first_name.$touch()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("middle_name") }}</label>
        <v-text-field
          :placeholder="$t('middle_name')"
          dense
          v-model="addProfilePayload.middle_name"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label
          >{{ $t("last_name_ind") }}<span style="color: #f00">*</span></label
        >
        <v-text-field
          :placeholder="$t('last_name_ind')"
          dense
          v-model="addProfilePayload.last_name"
          :error-messages="lnameErrors"
          @input="$v.addProfilePayload.last_name.$touch()"
          @blur="$v.addProfilePayload.last_name.$touch()"
          outlined
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("DOB") }}<span style="color: #f00">*</span></label>

        <v-menu
          ref="dobmenu"
          v-model="dobmenu"
          :close-on-content-click="false"
          lazy
          transition="scale-transition"
          :offset-x="true"
          full-width
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              v-model="addProfilePayload.dob"
              persistent-hint
              clearable
              v-on="on"
              type="date"
              dense
              :error-messages="dobErrors"
              @input="$v.addProfilePayload.dob.$touch()"
              @change="ValidateAge"
              :min="minDate"
              :max="maxDate"
              outlined
            >
            </v-text-field>
          </template>
        </v-menu>
      </v-col>

      <v-col cols="12" md="6">
        <label>{{ $t("Nationality") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :placeholder="$t('nationality_placeholder')"
          dense
          required
          v-model="addProfilePayload.nationality_code"
          :items="nationality_options"
          item-text="name"
          item-value="id"
          :error-messages="nationalityErrors"
          @input="$v.addProfilePayload.nationality_code.$touch()"
          @blur="$v.addProfilePayload.nationality_code.$touch()"
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("Gender") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :placeholder="$t('gender_placeholder')"
          :items="genderOptions"
          v-model="addProfilePayload.gender"
          :error-messages="genderErrors"
          @input="$v.addProfilePayload.gender.$touch()"
          dense
          outlined
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label
          >{{ $t("CountryOfBirth") }}<span style="color: #f00">*</span></label
        >
        <v-autocomplete
          :placeholder="$t('country_of_birth_ind')"
          :items="country_options"
          item-text="name"
          item-value="id"
          v-model="addProfilePayload.country_of_birth"
          :error-messages="countryErrors"
          @input="$v.addProfilePayload.country_of_birth.$touch()"
          dense
          outlined
        ></v-autocomplete>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickNext"
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { required } from "vuelidate/lib/validators";
import { findDatePrevious } from "../../../utils/helper";
import { alpha } from "../../../utils/validate";
import commonService from "@/services/CommonService/commonService";
import alerts from "@/mixins/alerts";
export default {
  mixins: [alerts],
  data: () => ({
    dobmenu: false,
    maxDate: "",
    minDate: "",
    isDobError: false,
    country_options: [],
    nationality_options: [],
    nationality_objs: [],
    genderOptions: [
      { text: "Male", value: "Male" },
      { text: "Female", value: "Female" },
    ],
    basicDetails: {
      first_name: "",
      last_name: "",
      middle_name: "",
      dob: "",
      nationality: "",
      gender: "",
      country_of_birth: "",
      nationality_code: "",
    },
  }),
  name: "BasicDetails",
  props: {
    addProfilePayload: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  mounted() {
    this.getNationalityList();
    this.maxDate = findDatePrevious(new Date(), 18);
    this.minDate = findDatePrevious(new Date(), 70);
  },
  computed: {
    fnameErrors() {
      console.log("log", this.$v);
      const errors = [];
      if (!this.$v.addProfilePayload.first_name.$dirty) return errors;
      !this.$v.addProfilePayload.first_name.required &&
        errors.push(this.$t("first_name"));
      !this.$v.addProfilePayload.first_name.alpha &&
        errors.push(this.$t("invalid_first_name"));
      return errors;
    },
    lnameErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.last_name.$dirty) return errors;
      !this.$v.addProfilePayload.last_name.required &&
        errors.push(this.$t("last_name"));
      !this.$v.addProfilePayload.last_name.alpha &&
        errors.push(this.$t("invalid_last_name"));
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.dob.$dirty) return errors;
      !this.$v.addProfilePayload.dob.required &&
        errors.push(this.$t("select_dob"));
      if (this.isDobError) {
        errors.push(this.$t("invalid_dob"));
      }
      return errors;
    },
    nationalityErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.nationality_code.$dirty) return errors;
      !this.$v.addProfilePayload.nationality_code.required &&
        errors.push(this.$t("nationality"));
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.gender.$dirty) return errors;
      !this.$v.addProfilePayload.gender.required &&
        errors.push(this.$t("gender"));
      return errors;
    },

    countryErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.country_of_birth.$dirty) return errors;
      !this.$v.addProfilePayload.country_of_birth.required &&
        errors.push(this.$t("select_country"));
      return errors;
    },
  },
  validations() {
    return {
      addProfilePayload: {
        first_name: {
          required,
          alpha,
        },
        last_name: {
          required,
          alpha,
        },
        dob: {
          required,
        },
        nationality_code: {
          required,
        },
        country_of_birth: {
          required,
        },
        gender: {
          required,
        },
      },
    };
  },
  watch: {
    "addProfilePayload.nationality_code"(val) {
      console.log(val);
      this.nationality_objs.map((i) => {
        if (i.nationality === val) {
          this.addProfilePayload.nationality_code_code = i.iso_alpha3;
        }
      });
    },
  },
  methods: {
    ValidateAge(value) {
      if (!value) this.isDobError = false;
      else this.isDobError = !(value <= this.maxDate && value >= this.minDate);
    },
    async getNationalityList() {
      const responseData = await commonService.getNationalityList();
      console.log("nationality,", responseData);
      responseData.data.map((i) => {
        this.nationality_options.push({
          name: i.nationality,
          id: i.iso_alpha3,
        });
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
        this.nationality_objs.push(i);
      });
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      console.log(
        `addProfilePayload ${JSON.stringify(this.addProfilePayload)}`
      );
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      this.$emit("onNextClick", this.addProfilePayload);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-name {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
</style>
