import { mapMutations } from "vuex";

export default {
  created() {
    this.showAlert("", "");
  },
  methods: {
    ...mapMutations(["setAlert"]),
    showAlert(color, message) {
      this.setAlert({ color, message, show: message.length > 0 });
    },
    showAlertError(message) {
      this.setAlert({ color: "error", message, show: true });
    },
    showAlertSuccess(message) {
      this.setAlert({ color: "success", message, show: true });
    },
  },
};
