<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <label>{{ $t("address") }}<span style="color: #f00">*</span></label>
        <v-text-field
          :placeholder="$t('address_placeholder')"
          v-model="addressInfo.address"
          :error-messages="addline1Errors"
          @input="$v.addressInfo.address.$touch()"
          @blur="$v.addressInfo.address.$touch()"
          outlined
          required
          dense
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("country_ind") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :placeholder="$t('country_placeholder')"
          :items="country_options"
          v-model="addressInfo.country_code"
          disabled
          item-text="name"
          item-value="id"
          :error-messages="countryErrors"
          @input="
            $v.addressInfo.country_code.$touch();
            onChangeCounty($event);
          "
          outlined
          required
          dense
        >
        </v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("state_ind") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          v-model="addressInfo.state_code"
          :items="state_options"
          item-text="name"
          item-value="id"
          :placeholder="$t('state_placeholder')"
          outlined
          required
          :error-messages="stateErrors"
          @input="$v.addressInfo.state_code.$touch()"
          @change="onChangeState($event)"
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label>{{ $t("city") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          v-if="haveCities"
          v-model="addressInfo.city_code"
          :placeholder="$t('city_placeholder')"
          :items="city_options"
          item-text="txt"
          item-value="val"
          :error-messages="cityErrors"
          @input="$v.addressInfo.city_code.$touch()"
          outlined
          required
          dense
        >
        </v-autocomplete>
        <v-text-field
          v-else
          v-model="cityValue"
          :placeholder="$t('enter_city')"
          :error-messages="citytextErrors"
          @input="$v.cityValue.$touch()"
          @blur="$v.cityValue.$touch()"
          outlined
          required
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label>{{ $t("postalCode") }}<span style="color: #f00">*</span></label>
        <v-combobox
          v-model="addressInfo.zip_code"
          :error-messages="postcodeErrors"
          :items="postalCodeslist"
          @input="$v.addressInfo.zip_code.$touch()"
          :placeholder="$t('postalcode_placeholder')"
          outlined
          dense
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <label :description="$t('optional')">
          {{ $t("phoneNumber") }}<span style="color: #f00">*</span>
        </label>
        <v-text-field
          :disabled="selected_login_type === 'mobile'"
          type="number"
          hide-spin-buttons
          v-model="addressInfo.phone_number"
          :hint="'+61 xx xxxx xxxx'"
          v-mask="'##############'"
          :error-messages="phoneErrors"
          @input="$v.addressInfo.phone_number.$touch()"
          @blur="$v.addressInfo.phone_number.$touch()"
          :placeholder="$t('mobile_number_placeholder')"
          outlined
          required
          dense
          prefix="+61"
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label>{{ $t("emailID") }}</label>
        <v-text-field
          :disabled="selected_login_type === 'email'"
          v-model="addressInfo.email_id"
          :placeholder="$t('emailID')"
          :error-messages="emailErrors"
          @input="$v.addressInfo.email_id.$touch()"
          @blur="$v.addressInfo.email_id.$touch()"
          outlined
          required
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickBack"
          ><v-icon dark left>mdi-arrow-left</v-icon>
          {{ $t("backLabel") }}</v-btn
        >
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          outlined
          @click="onClickNext"
          >{{ $t("next") }} <v-icon dark right>mdi-arrow-right</v-icon></v-btn
        >
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, requiredIf, numeric, email } from "vuelidate/lib/validators";
import { alpha, addresslen } from "../../../utils/validate";
import commonService from "@/services/CommonService/commonService";
import alerts from "@/mixins/alerts";
export default {
  name: "AddressSection",
  mixins: [alerts],
  data() {
    return {
      selected_login_type: "",
      country_options: [],
      state_options: [],
      city_options: [],
      addressInfo: {
        address: "",
        country: {
          id: 132,
          iso2: "AU",
          iso3: "AUS",
          name: "Australia",
        },
        state: {},
        city: {},
        postal_code: "",
        email_id: "",
        district: "",
      },

      isValidNumber: false,
      cityValue: "",
      haveCities: true,
      postalCodeslist: [],
    };
  },
  props: {
    addProfilePayload: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  watch: {
    addProfilePayload: {
      handler(newVal) {
        console.log("profile Data", newVal);
        this.addressInfo.address = newVal.address;
        this.addressInfo.zip_code = newVal.zip_code;
        this.addressInfo.phone_number = newVal.phone_number;
      },
    },
  },
  computed: {
    addline1Errors() {
      const errors = [];
      if (!this.$v.addressInfo.address.$dirty) return errors;
      !this.$v.addressInfo.address.required && errors.push(this.$t("address"));
      !this.$v.addressInfo.address.addresslen &&
        errors.push(this.$t("invalid_address"));
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.addressInfo.email_id.$dirty) return errors;
      !this.$v.addressInfo.email_id.email &&
        errors.push(this.$t("invalid_email"));
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.addressInfo.country_code.$dirty) return errors;
      !this.$v.addressInfo.country_code.required &&
        errors.push(this.$t("select_country"));
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.addressInfo.state_code.$dirty) return errors;
      !this.$v.addressInfo.state_code.required &&
        errors.push(this.$t("select_state"));
      return errors;
    },
    cityErrors() {
      const errors = [];
      if (!this.$v.addressInfo.city_code.$dirty) return errors;
      !this.$v.addressInfo.city_code.required &&
        errors.push(this.$t("select_city"));
      return errors;
    },
    citytextErrors() {
      const errors = [];
      if (!this.$v.cityValue.$dirty) return errors;
      !this.$v.cityValue.required && errors.push(this.$t("enter_city"));
      !this.$v.cityValue.alpha && errors.push(this.$t("invalid_city_name"));
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.addressInfo.phone_number.$dirty) return errors;
      !this.$v.addressInfo.phone_number.required &&
        errors.push(this.$t("phone_num"));
      return errors;
    },

    postcodeErrors() {
      const errors = [];
      if (!this.$v.addressInfo.zip_code.$dirty) return errors;
      !this.$v.addressInfo.zip_code.required &&
        errors.push(this.$t("postal_code"));
      !this.$v.addressInfo.zip_code.numeric &&
        errors.push(this.$t("invalid_postal_code"));
      return errors;
    },
  },
  validations() {
    return {
      addressInfo: {
        address: {
          required,
          addresslen,
        },
        state_code: {
          required,
        },
        phone_number: { required },
        city_code: {
          required: requiredIf(function () {
            if (this.haveCities) return true;
            else return false;
          }),
        },
        country_code: {
          required,
        },
        email_id: {
          email,
        },

        zip_code: {
          required,
          numeric,
        },
      },
      cityValue: {
        required: requiredIf(function () {
          if (!this.haveCities) return true;
          else return false;
        }),
        alpha,
      },
    };
  },
  created() {
    this.getPostalCodes();
    this.getCountryList();
    this.addressInfo.country_code = "AUS";
    this.onChangeCounty("AUS");
    this.selected_login_type = sessionStorage.getItem("selected_login_type");
    if (this.selected_login_type === "email") {
      this.addressInfo.email_id = sessionStorage.getItem("selected_user_name");
    } else {
      this.addressInfo.phone_number =
        sessionStorage.getItem("selected_user_name");
    }
    if (this.addProfilePayload.country_id) {
      this.onChangeCounty(this.addProfilePayload.country_id);
    }
    if (this.addProfilePayload.state_id) {
      this.onChangeState(this.addProfilePayload.state_id);
    }
  },
  methods: {
    onChangeNumber(e) {
      console.log("e", e);
      this.isValidNumber = e.isValid;
      this.beneficiaryReqBody.phone_number_cty = e.countryCode;
    },
    checkNumber(val) {
      if (val.substring(0, 2) === "61") {
        return val;
      } else {
        return "61" + val;
      }
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    onClickNext() {
      // this.addressInfo.city_code = this.cityValue;
      console.log(`addressInfo: ${JSON.stringify(this.addressInfo)}`);
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit(
        "onNextClick",
        Object.assign(this.addProfilePayload, {
          address: this.addressInfo.address,
          country_code: this.addressInfo.country_code,
          state_code: this.addressInfo.state_code,
          city_code: this.addressInfo.city_code
            ? this.addressInfo.city_code
            : this.cityValue,
          district: this.addressInfo.district,
          zip_code: this.addressInfo.zip_code,
          phone_number: this.checkNumber(this.addressInfo.phone_number),
          email_id: this.addressInfo.email_id,
        })
      );
    },
    async getPostalCodes() {
      try {
        const responseData = await commonService.getPostalCodes();
        if (responseData.data.status_code === 200) {
          this.postalCodeslist = [...responseData.data.data];
        }
        console.log("postalCodes", responseData);
      } catch (err) {
        console.log(err);
      }
    },
    async getCountryList() {
      const responseData = await commonService.getNationalityList();
      responseData.data.map((i) => {
        this.country_options.push({
          name: i.country_name,
          id: i.iso_alpha3,
        });
      });
    },
    async onChangeCounty(iso3) {
      const responseData = await commonService.getStateByIso3(iso3);
      this.state_options = responseData.data.data.map((i) => {
        return {
          name: i.name,
          id: i.state_code,
        };
      });
    },
    async onChangeState(state) {
      console.log("state", state);
      let id = {
        state_code: state,
        country_code: this.addressInfo.country_code,
      };
      const responseData = await commonService.getCityByIso3(id);
      this.city_options = responseData.data.data.map((i) => {
        return {
          val: i.id,
          txt: i.name,
        };
      });

      if (this.city_options.length === 0) {
        this.haveCities = false;
      } else {
        this.haveCities = true;
        console.log("have list");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.repend-inner {
  margin-top: 0px !important;
}
</style>
