import request from "@/Axios/request";
const baseUrl = `${process.env.VUE_APP_USER_SERVICE}/api/v1/user`;

export default {
  logout() {
    return request({
      url: `${baseUrl}/logout`,
      method: "get",
    });
  },
  // Login page API's
  CheckTFAstatus(_userDetails) {
    return request({
      url: `${baseUrl}/get-two-factor?mobile=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
      method: "get",
    });
  },
  UpadteTFAstatus(data) {
    return request({
      url: `${baseUrl}/enable-two-factor?mobile=${data.mobile_number}&two_factor_status=${data.status}&user_type=${data.user_type}`,
      method: "put",
      data: data,
    });
  },
  login(data, params) {
    return request({
      url: `${baseUrl}/login?login_type=${params.login_type}`,
      method: "post",
      data: data,
    });
  },
  // Create password
  changePassword(data) {
    return request({
      url: `${baseUrl}/forget-password`,
      method: "post",
      data: data,
    });
  },
  // profle
  getProfileDetails() {
    return request({
      url: `${baseUrl}/profile`,
      method: "get",
    });
  },
  validatePassword(data) {
    return request({
      url: `${baseUrl}/validate-password`,
      method: "post",
      data: data,
    });
  },

  getVerifyMail(user_email) {
    return request({
      url: `${baseUrl}/verify-email?user_email=${user_email}`,
      method: "get",
    });
  },
  getOTPbyMail(user_email) {
    return request({
      url: `${baseUrl}/email/get-otp?user_email=${user_email}`,
      method: "get",
    });
  },
  confirmOTPmail(params) {
    return request({
      url: `${baseUrl}/email-verify/confirm-otp`,
      method: "post",
      params,
    });
  },

  updateOldpwtoNewpwd(oldPassword, newPassword) {
    return request({
      url: `${baseUrl}/update-password?old_password=${oldPassword}&password=${newPassword}`,
      method: "put",
    });
  },

  updateLanguage(params) {
    return request({
      url: `${baseUrl}/change-language?language`,
      method: "get",
      params,
    });
  },
  uploadProfileImage(fileData) {
    return request({
      url: `${baseUrl}/profile/upload`,
      method: "post",
      fileData,
    });
  },

  //update password
  updatePassword() {
    return request({
      url: `${baseUrl}/get-password-format`,
      method: "get",
    });
  },

  validateOtpPassword(data) {
    return request({
      url: `${baseUrl}/forget-password/confirm-otp`,
      method: "post",
      data: data,
    });
  },

  get_otpMobile(_userDetails) {
    return request({
      url: `${baseUrl}/mobile/get-otp?mobile_number=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
      method: "get",
    });
  },
  get_otpEmail(_userDetails) {
    return request({
      url: `${baseUrl}/email/get-otp`,
      method: "get",
      params: _userDetails,
    });
  },
  registerMobile(_userDetails) {
    return request({
      url: `${baseUrl}/verify-mobile?mobile_number=${_userDetails.mobile_number}&user_type=${_userDetails.user_type}`,
      method: "get",
    });
  },
  registerEmail(_userDetails) {
    return request({
      url: `${baseUrl}/validate-email`,
      method: "get",
      params: _userDetails,
    });
  },
  confirm_otp_mobile(data) {
    return request({
      url: `${baseUrl}/mobile/confirm-otp?mobile_number`,
      method: "post",
      data: data,
      params: data,
    });
  },
  confirm_otp_email(data) {
    return request({
      url: `${baseUrl}/email/confirm-otp`,
      method: "post",
      data: data,
      params: data,
    });
  },
  getPasswordformat() {
    return request({
      url: `${baseUrl}/get-password-format`,
      method: "get",
    });
  },
  registerAccount_mobile(data) {
    return request({
      url: `${baseUrl}/register-mobile`,
      method: "post",
      data: data,
    });
  },
  registerAccount_username(data) {
    return request({
      url: `${baseUrl}/register`,
      method: "post",
      data: data,
    });
  },
  registerAccount(data) {
    return request({
      url: `${baseUrl}/mobile/register`,
      method: "post",
      data: data,
    });
  },
  forgotPassword(data) {
    return request({
      url: `${baseUrl}/forget-password/get-otp-mobile`,
      method: "get",
      params: {
        mobile_number: data.mobile_number,
        user_type: data.user_type,
      },
      data: data,
    });
  },
  forgotemailPassword(data) {
    return request({
      url: `${baseUrl}/forget-password/get-otp`,
      method: "get",
      params: data,
      data: data,
    });
  },

  get_otp_Mobile2FA(data) {
    return request({
      url: `${baseUrl}/two-step-auth/get-otp-mobile`,
      method: "get",
      params: {
        mobile_number: data.mobile_number,
        user_type: data.user_type,
      },
    });
  },
  get_otp_Email2FA(data) {
    return request({
      url: `${baseUrl}/two-step-auth/get-otp`,
      method: "get",
      params: {
        user_email: data.user_email,
        user_type: data.user_type,
      },
    });
  },
  confirm_otp_Mobile2FA(data) {
    return request({
      url: `${baseUrl}/two-step-auth/confirm-otp-mobile`,
      method: "post",
      data: data,
      params: data,
    });
  },
  confirm_otp_Email2FA(data) {
    return request({
      url: `${baseUrl}/two-step-auth/confirm-otp`,
      method: "post",
      data: data,
      params: data,
    });
  },
  //----------------- Create Individual User---------------------
  createIndUser(data) {
    return request({
      url: `${baseUrl}/add-profile`,
      method: "post",
      data: data,
    });
  },
  //-------------------Submit KYC Data-------------------------------------
  getkycdetails() {
    return request({
      url: `${baseUrl}/kyc-summery`,
      method: "get",
    });
  },
  getkycstatus() {
    return request({
      url: `${baseUrl}/get-kyc-status`,
      method: "get",
    });
  },
  uploadKYCdocs(data, params) {
    return request({
      url: `${baseUrl}/kyc/upload`,
      method: "post",
      data: data,
      params: params,
    });
  },
  clearKycDetails(params) {
    return request({
      url: `${baseUrl}/clear-kyc`,
      method: "post",
      params: params,
    });
  },

  eKYCdetalsFacetoFace(data) {
    return request({
      url: `${baseUrl}/face-to-face/kyc/shedule`,
      method: "post",
      data,
    });
  },
  KYCdetalsFacetoFace(data) {
    return request({
      url: `${baseUrl}/kyc`,
      method: "post",
      data,
    });
  },

  //---------------------------- Send Money -------------------------------------------------//
  getProfileData() {
    return request({
      url: `${baseUrl}/dashboard/profile/status`,
      method: "get",
    });
  },
  getEvolteUser() {
    return request({
      url: `${baseUrl}/get-draft-profile-data`,
      method: "get",
    });
  },
};
