<template>
  <v-container>
    <loading
      :active="loader"
      :is-full-page="fullPage"
      :opacity="0.7"
      color="#ff6b00"
      :height="height"
      :width="width"
    />
    <v-row>
      <v-col cols="12" md="6">
        <label>{{ $t("Occupation") }}<span style="color: #f00">*</span></label>
        <v-autocomplete
          :items="occupation_options"
          item-text="name"
          item-value="id"
          v-model="addProfilePayload.occupation_code"
          :error-messages="occupationErrors"
          @input="$v.addProfilePayload.occupation_code.$touch()"
          :placeholder="$t('occupation_placeholder')"
          outlined
          required
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label
          >{{ $t("EmployerName")
          }}<span style="color: #f00" v-if="empNameunrequired">*</span></label
        >
        <v-text-field
          v-model="addProfilePayload.employer_name"
          :error-messages="employerErrors"
          @input="$v.addProfilePayload.employer_name.$touch()"
          :placeholder="$t('employer_name')"
          outlined
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="6">
        <label
          >{{ $t("IncomeSource") }}<span style="color: #f00">*</span></label
        >
        <v-autocomplete
          v-model="addProfilePayload.source_of_fund_code"
          :items="source_options"
          item-text="name"
          item-value="id"
          :error-messages="incomeSourceErrors"
          @input="$v.addProfilePayload.source_of_fund_code.$touch()"
          @blur="$v.addProfilePayload.source_of_fund_code.$touch()"
          :placeholder="$t('source_of_income_placeholder')"
          outlined
          required
          dense
        ></v-autocomplete>
      </v-col>
      <v-col cols="12" md="6">
        <label
          >{{ $t("ReferralID")
          }}<span v-if="is_self_beneficiary" style="color: #f00">*</span></label
        >
        <v-text-field
          v-model="addProfilePayload.sp_referral_code"
          :placeholder="$t('ReferralID')"
          :disabled="is_refcode"
          :error-messages="referralIderrors"
          @input="$v.addProfilePayload.sp_referral_code.$touch()"
          outlined
          dense
        >
        </v-text-field>
      </v-col>

      <v-col cols="12" md="12">
        <v-btn
          class="float-left mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onClickBack"
          outlined
          ><v-icon dark left>mdi-arrow-left</v-icon> {{ $t("backLabel") }}
        </v-btn>
        <v-btn
          class="float-right mt-5 px-5 btn-primary-outlined text-capitalize"
          @click="onclickNext"
          outlined
        >
          <div class="button-loader" v-if="loader">
            <span class="spinner-border spinner-border-sm"></span>
          </div>
          {{ $t("submit_ind") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { required, requiredIf, alphaNum } from "vuelidate/lib/validators";
import { getCookieValue } from "../../../utils/getCookies";
import Loading from "vue-loading-overlay";
import commonService from "@/services/CommonService/commonService";
import userservice from "@/services/UserService/userservice";
import alerts from "@/mixins/alerts";
export default {
  name: "AccountSection",
  components: {
    Loading,
  },
  mixins: [alerts],
  data() {
    return {
      getCookieValue,
      name: "",
      isSubmitted: false,
      fullPage: true,
      height: 100,
      width: 100,
      is_refcode: false,
      unrequiredOccupations: [74, 102, 76, 87, 94, 92],
      occupation_options: [],
      occupation_objs: [],
      source_options: [],
      sourceList_objs: [],
      sourceOfIncome: {
        occupation: "",
        ename: "",
        incomeSource: "",
      },
      is_self_beneficiary: false,
    };
  },
  props: {
    addProfilePayload: {
      type: Object,
      default() {
        return {};
      },
    },
    loader: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  validations() {
    return {
      addProfilePayload: {
        occupation_code: {
          required,
        },
        source_of_fund_code: {
          required,
        },
        sp_referral_code: {
          required: requiredIf(function () {
            if (this.is_self_beneficiary) return true;
            else return false;
          }),
          alphaNum,
        },
        employer_name: {
          required: requiredIf(function () {
            if (
              this.unrequiredOccupations.includes(
                this.addProfilePayload.occupation_code
              )
            )
              return false;
            else return true;
          }),
        },
      },
    };
  },
  created() {
    this.is_self_beneficiary = JSON.parse(
      sessionStorage.getItem("is_self_beneficiary")
    );
  },
  mounted() {
    this.getOccupation();
    this.getSourceFunds();
    const spref_code = this.getCookieValue("sp_referral_code");
    if (spref_code) {
      this.addProfilePayload.sp_referral_code = spref_code;
      this.is_refcode = true;
    } else {
      this.is_refcode = false;
    }
  },
  computed: {
    empNameunrequired() {
      if (
        this.unrequiredOccupations.includes(
          this.addProfilePayload.occupation_code
        )
      )
        return false;
      else return true;
    },
    occupationErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.occupation_code.$dirty) return errors;
      !this.$v.addProfilePayload.occupation_code.required &&
        errors.push(this.$t("select_occupation"));
      return errors;
    },
    incomeSourceErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.source_of_fund_code.$dirty) return errors;
      !this.$v.addProfilePayload.source_of_fund_code.required &&
        errors.push(this.$t("source_of_income"));
      return errors;
    },
    referralIderrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.sp_referral_code.$dirty) return errors;
      !this.$v.addProfilePayload.sp_referral_code.required &&
        errors.push(this.$t("Enter_valid_refID"));
      !this.$v.addProfilePayload.sp_referral_code.alphaNum &&
        errors.push(this.$t("Enter_valid_refID"));
      return errors;
    },
    employerErrors() {
      const errors = [];
      if (!this.$v.addProfilePayload.employer_name.$dirty) return errors;
      !this.$v.addProfilePayload.employer_name.required &&
        errors.push(this.$t("Enter_empoyer_name"));
      return errors;
    },
  },
  watch: {
    user_type: {
      handler(newval) {
        if (newval) {
          setTimeout(() => {
            this.getOccupation();
            this.getSourceFunds();
          }, 250);
        }
      },
    },
  },
  methods: {
    onclickNext() {
      console.log(
        `addProfilePayload: ${JSON.stringify(this.addProfilePayload)}`
      );
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      this.$emit("onNextClick", this.addProfilePayload);
      this.userIndividualData();
    },
    onClickBack() {
      this.$emit("onClickBack");
    },
    async getOccupation() {
      const responseData = await commonService.getParamterDropdown("OCC");
      this.occupation_options = responseData.data.data.map((i) => {
        return {
          name: i.value,
          id: i.value_code,
        };
      });
      responseData.data.map((i) => {
        this.occupation_objs.push(i);
      });
    },
    async getSourceFunds() {
      const responseData = await commonService.getParamterDropdown("SOF");
      console.log("responseData", responseData.data);
      responseData.data.data.map((i) => {
        this.source_options.push({
          name: i.value,
          id: i.value_code,
        });
        this.sourceList_objs.push(i);
      });
    },
    async userIndividualData() {
      this.addProfilePayload.user_type = "IND";
      this.addProfilePayload.onboard_stage = "4";
      if (this.addProfilePayload.email_id === "") {
        this.addProfilePayload.email_id = null;
      }
      this.loader = true;
      this.addProfilePayload.user_type = sessionStorage.getItem("user_type");
      try {
        const responseData = await userservice.createIndUser(
          this.addProfilePayload
        );
        if (responseData && responseData.data.status_code == 200) {
          this.loader = false;
          this.showAlertSuccess(responseData.data.message);
          setTimeout(() => this.$router.push({ name: "KYC" }), 1000);
        } else {
          this.loader = false;
          this.showAlertError(responseData.data.message);
        }
      } catch (error) {
        this.loader = false;
        console.log(error);
        this.showAlertError("something went wrong please try again sometime!");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/global.scss";
.invalid-feedback-password {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-confirm {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
.invalid-feedback-swift {
  display: flex;
  flex-direction: column;
  text-align: initial;
  font-size: 13px;
  background-color: #f5f5f5;
  color: #dc3545;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
    margin: auto;
  }
}
</style>
