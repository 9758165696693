export function getCookieValue(name) {
  // Split the cookie string into an array of individual cookies
  const cookies = document.cookie.split(";");
  // Loop through the cookies and find the one with the specified name
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    // Check if the cookie starts with the name we're looking for
    if (cookie.startsWith(`${name}=`)) {
      // Return the cookie value, which is everything after the '=' sign
      return cookie.substring(name.length + 1);
    }
  }
  // If the cookie isn't found, return null
  return null;
}
