import request from "@/Axios/request";
const baseUrl = `${process.env.VUE_APP_COMMON_SERVICE}/api/v1/dropdown`;

export default {
  getBeneficiaryCountryList() {
    return request({
      url: `${baseUrl}/get-all-country`,
      method: "get",
    });
  },
  getStateByIso3(country_code) {
    return request({
      url: `${baseUrl}/get-all-states?country_code=${country_code}`,
      method: "get",
    });
  },
  getCityByIso3(params) {
    return request({
      url: `${baseUrl}/get-all-cities`,
      method: "get",
      params: params,
    });
  },
  getNationalityList() {
    return request({
      url: `${baseUrl}/get-all-country-data`,
      method: "get",
    });
  },
  getPostalCodes() {
    return request({
      url: `${baseUrl}/all-zipcode`,
      method: "get",
    });
  },
  getParamterDropdown(parameter_code) {
    return request({
      url: `${baseUrl}/get-parameter-data?parameter_code=${parameter_code}`,
      method: "get",
    });
  },
  getPaymentmodeByCountry(country){
    return request({
      url: `${baseUrl}/get-payment-modes?payout_country=${country}`,
      method: "get",
    });
  },
  getPayLocation(selected_country,payment_mode){
    return request({
      url: `${baseUrl}/get-bank-list?country=${selected_country}&payment_mode=${payment_mode}`,
      method: "get",
    });
  },
  checkPolicyNumber(policy_number){
    return request({
      url: `${baseUrl}/inquire-insurance?insurance_code=${policy_number}&currency_code=IDR`,
      method: "get",
    });
  },
  getDropdownList(code){
    return request({
      url: `${baseUrl}/get-dropdown?parameter_code=${code}`,
      method: "get",
    });
  },
  getDropdownListUser(params){
    return request({
      url: `${baseUrl}/get-dropdown`,
      method: "get",
      params:params
    });
  },
  //------------------------------------------------SEND MONEY----------------------------------------------//
  getcountryExchangeRate(){
    return request({
      url: `${baseUrl}/get-exchange-rates?send_country=MYS`,
      method: "get",
    });
  },
  getExchangefee(params){
    return request({
      url: `${baseUrl}/exchange-amount-data`,
      method: "get",
      params
    });
  },
  getGraphdata(params) {
    return request({
      url: `${baseUrl}/get-graph-data`,
      method: "get",
      params: params,
    });
  },
  getExchangeRate(country_code) {
    return request({
      url: `${baseUrl}/get-graph-data?country=${country_code}&no_of_days=7`,
      method: "get",
    });
  },
  getPaymentMode(country_name){
    return request({
      url: `${baseUrl}/get-payment-modes?payout_country=${country_name}`,
      method: "get",
    });
  },
};
